<template>
  <div>
    <label style="font-weight:bold;font-size:20px;display:block;margin-bottom:15px;" class="label">Passcode settings</label>
    <div style="display: flex;justify-content: space-between;align-items: center;">
      <label class="label">Active passcode</label>
      <v-switch color="#6ab64a" v-model="active" @change="changeActive"></v-switch>
    </div>
    <div v-if="passcode.active">
      <div style="margin-bottom: 15px;max-width:100%">
        <label class="label" style="display:block;margin-bottom: 15px">Logo</label>
        <vue-dropzone
            id="upload"
            ref="upload"
            :options="dropzoneOptions"
            :destroyDropzone="false"
            @vdropzone-sending="customEvent"
            @vdropzone-removed-file="handleRemove"
                @vdropzone-mounted="mountDropzone"
            @vdropzone-success="handleSuccess"
        >
        </vue-dropzone>
      </div>
      <div style="margin-bottom: 15px">
        <label class="label" style="display:block;margin-bottom: 15px">Background</label>
        <vue-dropzone
            id="background"
            ref="background"
            :options="dropzoneOptions"
            :destroyDropzone="false"
            @vdropzone-sending="customEvent"
            @vdropzone-mounted="mountBackgroundDropzone"
            @vdropzone-removed-file="handleBackgroundRemove"
            @vdropzone-success="handleBackgroundSuccess"
        >
        </vue-dropzone>
      </div>
      <div style="margin-bottom: 15px;display: flex;align-items: center;">
        <label class="label" style="margin-right: 15px;width:175px;display: block;">Background Color</label>
        <v-menu
            top
            z-index="130"
            nudge-left="16"
            nudge-bottom="98"
            :close-on-content-click="false"
            v-model="background_color_show"
        >
          <template v-slot:activator="{ on }">
            <div
                class="custom__menu--color"
                :style="`background-color:${passcode.background_color}`"
                v-on="on"
            />
          </template>
          <v-card>
            <v-card-text class="pa-0">
              <v-color-picker
                  v-model="passcode.background_color"
                  flat
              />
            </v-card-text>
          </v-card>
        </v-menu>
      </div>

      <div style="margin-bottom: 15px;display: flex;align-items: center;">
        <label class="label" style="width:175px;margin-right: 15px;display: block;">Buttons Color</label>
        <v-menu
            top
            z-index="130"
            nudge-left="16"
            nudge-bottom="98"
            :close-on-content-click="false"
            v-model="btn_color_show"
        >
          <template v-slot:activator="{ on }">
            <div
                class="custom__menu--color"
                :style="`background-color:${passcode.btn_color}`"
                v-on="on"
            />
          </template>
          <v-card>
            <v-card-text class="pa-0">
              <v-color-picker
                  v-model="passcode.btn_color"
                  flat
              />
            </v-card-text>
          </v-card>
        </v-menu>
      </div>

      <div style="margin-bottom: 15px;display: flex;align-items: center;">
        <label class="label" style="width:175px;margin-right: 15px;display: block;">Active Buttons Color</label>
        <v-menu
            top
            z-index="130"
            nudge-left="16"
            nudge-bottom="98"
            :close-on-content-click="false"
            v-model="active_btn_color_show"
        >
          <template v-slot:activator="{ on }">
            <div
                class="custom__menu--color"
                :style="`background-color:${passcode.active_btn_color}`"
                v-on="on"
            />
          </template>
          <v-card>
            <v-card-text class="pa-0">
              <v-color-picker
                  v-model="passcode.active_btn_color"
                  flat
              />
            </v-card-text>
          </v-card>
        </v-menu>
      </div>

      <v-tabs class="langs__tabs" style="margin-bottom: 15px"  v-model="activeLang">
        <v-tab
            v-for="lang in landing.advanced.selected_langs"
            :key="lang+'lite'"
            @change="changeLang(lang)"
        >
          <img :src="landing.advanced.langs.find(x => x.value === lang).flag" alt="" style="width:20px;height:15px;margin-right:5px;">
          {{ lang }}
        </v-tab>
        <v-tab-item v-for="lang in landing.advanced.selected_langs" :key="lang+'pro'">
          <label style="display:block;margin-bottom: 15px" class="label">Text</label>
          <Editor
              api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
              :init="options"
              v-model="passcode.text[lang]"></Editor>
        </v-tab-item>
      </v-tabs>

      <div style="margin-bottom: 15px;display: flex;align-items: center;">
        <label class="label" style="margin-right: 15px;display: block;">Password</label>
       <v-text-field :maxlength="8" :minlength="4" type="number" outlined dense hide-details style="max-width: 300px;" v-model="passcode.password"></v-text-field>
      </div>
<!--      <v-btn small color="primary" outlined @click="saveChanges">Edit</v-btn>-->
    </div>
    <PopupSuccess ref="PopupSuccess" title="Success!" :content="successMessage"/>
  </div>
</template>

<script>
  import {mapState,mapActions} from 'vuex';
  import vueDropzone from 'vue2-dropzone'
  import "vue2-dropzone/dist/vue2Dropzone.min.css";
  import PopupSuccess from "../../Popup/PopupSuccess";
  export default {
    components:{
      PopupSuccess,
      vueDropzone
    },
    name: "PasscodePage",
    data(){
      return{
        btn_color_show:false,
        active_btn_color_show:false,
        active:false,
        background_color_show:false,
        successMessage:"",
        dropzoneOptions: {
          url: () => {
            return process.env.VUE_APP_API_URL + "upload-image";
          },
          thumbnailMethod: "contain",
          acceptedFiles: "image/*",
          addRemoveLinks: true,
          maxFilesize: 3,
          maxFiles: 1,
          destroyDropzone: false,
        },
        options: {
          images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
          images_file_types: 'jpg,svg,webp,gif,png',
          images_upload_handler: async  (blobInfo, success, failure) => {
            let formData = new FormData();
            let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
            formData.append("lending_settings_id", this.$store.state.landing.settings_id);
            formData.append("image", _file);
            formData.append("file", _file);
            const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
            success(data);
          },
          plugins:
            "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",

          mobile: {
            images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
            images_file_types: 'jpg,svg,webp,gif,png',
            images_upload_handler: async  (blobInfo, success, failure) => {
              let formData = new FormData();
              let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
              formData.append("lending_settings_id", this.$store.state.landing.settings_id);
              formData.append("image", _file);
              formData.append("file", _file);
              const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
              success(data);
            },
            plugins:
              "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
          },
          menu: {
            tc: {
              title: "Comments",
              items: "addcomment showcomments deleteallconversations",
            },
          },
          menubar: "file edit view insert format tools table tc help",
          toolbar:
            "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
          autosave_ask_before_unload: true,min_height:200,
          autosave_interval: "30s",
          autosave_prefix: "{path}{query}-{id}-",
          autosave_restore_when_empty: false,
          autosave_retention: "2m",
          image_advtab: true,
          link_list: [
            { title: "My page 1", value: "https://www.tiny.cloud" },
            { title: "My page 2", value: "http://www.moxiecode.com" },
          ],
          image_list: [
            { title: "My page 1", value: "https://www.tiny.cloud" },
            { title: "My page 2", value: "http://www.moxiecode.com" },
          ],
          image_class_list: [
            { title: "None", value: "" },
            { title: "Some class", value: "class-name" },
          ],
          importcss_append: true,
          templates: [
            {
              title: "New Table",
              description: "creates a new table",
              content:
                '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
            },
            {
              title: "Starting my story",
              description: "A cure for writers block",
              content: "Once upon a time...",
            },
            {
              title: "New list with dates",
              description: "New List with dates",
              content:
                '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
            },
          ],
          template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
          template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
          height: 200,
          image_caption: true,
          quickbars_selection_toolbar:
            "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
          noneditable_noneditable_class: "mceNonEditable",
          toolbar_mode: "sliding",
          content_style: ".mymention{ color: gray; }",
          contextmenu: "link image imagetools table configurepermanentpen",
          skin: "oxide",

          setup:function(editor){
            editor.ui.registry.addButton('custom_button', {
              text: 'Add Button',
              onAction: function() {
                // Open a Dialog
                editor.windowManager.open({
                  title: 'Add custom button',
                  body: {
                    type: 'panel',
                    items: [{
                      type: 'input',
                      name: 'button_label',
                      label: 'Button label',
                      flex: true
                    },{
                      type: 'input',
                      name: 'button_href',
                      label: 'Button href',
                      flex: true
                    },{
                      type: 'selectbox',
                      name: 'button_target',
                      label: 'Target',
                      items: [
                        {text: 'None', value: ''},
                        {text: 'New window', value: '_blank'},
                        {text: 'Self', value: '_self'},
                        {text: 'Parent', value: '_parent'}
                      ],
                      flex: true
                    },{
                      type: 'selectbox',
                      name: 'button_style',
                      label: 'Style',
                      items: [
                        {text: 'Success', value: 'success'},
                        {text: 'Info', value: 'info'},
                        {text: 'Warning', value: 'warning'},
                        {text: 'Error', value: 'error'}
                      ],
                      flex: true
                    }]
                  },
                  onSubmit: function (api) {

                    var html = '<a href="'+api.getData().button_href+'" class="v-btn v-btn--outlined theme--light v-size--small '+api.getData().button_style+'--text" rel="'+api.getData().button_rel+'" target="'+api.getData().button_target+'">'+api.getData().button_label+'</a>';

                    // insert markup
                    editor.insertContent(html);

                    // close the dialog
                    api.close();
                  },
                  buttons: [
                    {
                      text: 'Close',
                      type: 'cancel',
                      onclick: 'close'
                    },
                    {
                      text: 'Insert',
                      type: 'submit',
                      primary: true,
                      enabled: false
                    }
                  ]
                });
              }
            });
          },
          content_css: ["/custom_tinymce.css"],
        },
        defaultPasscode:{
          logo:'https://guesteco.com/logo.svg',
          text:{
            en:""
          },
          btn_color:"#fff",
          active_btn_color:"#000",
          background:null,
          background_color:"#fff",
          active:0,
          password:'0000',
          hotel_id:null
        }
      }
    },
    computed:{
      ...mapState('passcode',['passcode']),
      ...mapState(['landing']),
      activeLang:{
        set:function(val){

        },
        get:function(){
          return this.landing.advanced.selected_langs.indexOf(this.landing.current_lang)
        }
      },
    },
    async mounted(){
      await this.getPasscodeByHotel(this.$route.params.id);
      if(this.passcode.active){
        this.active = true;
      }
    },
    methods:{
      ...mapActions('passcode',['getPasscodeByHotel','postPasscode','putPasscode']),
      async saveChanges(){
        await this.putPasscode(this.passcode);
        this.successMessage = 'Changes successfully saved!';
        this.$refs.PopupSuccess.open();
      },
      mountBackgroundDropzone(){
        if(this.passcode.background){
          let mockFile = {
            accepted: true,
            kind: "image",
          };
          this.$refs.background.manuallyAddFile(mockFile,  this.passcode.background);
          this.$refs.background.dropzone.emit("thumbnail", mockFile, this.passcode.background);
          this.$refs.background.dropzone.emit("complete", mockFile);
        }
      },
      mountDropzone(){
        if(this.passcode.logo){
          let mockFile = {
            accepted: true,
            kind: "image",
          };
          this.$refs.upload.manuallyAddFile(mockFile,  this.passcode.logo);
          this.$refs.upload.dropzone.emit("thumbnail", mockFile, this.passcode.logo);
          this.$refs.upload.dropzone.emit("complete", mockFile);
        }
      },
      handleBackgroundRemove(){
        this.passcode.background = null
      },
      handleBackgroundSuccess(file){
        this.passcode.background = file.xhr.response;
      },
      handleSuccess(file){
        this.passcode.logo = file.xhr.response;
      },
      handleRemove(){
        this.passcode.logo = null;
      },
      async customEvent(_file, xhr, formData) {
        xhr.setRequestHeader(
          "Authorization",
          `Bearer ${this.$store.state.token}`
        );
        formData.append("lending_settings_id", this.landing.settings_id);
        formData.append("image", _file);
      },
      changeLang(lang) {
        this.$store.commit("landing/setCurrentLang", lang);
      },
      async changeActive(){
        if(this.active){
          if(!this.passcode.id){
            this.defaultPasscode.hotel_id = +this.$route.params.id;
            await this.postPasscode(this.defaultPasscode);
          }
          this.passcode.active = 1;
          await this.putPasscode(this.passcode);
        }else{
          this.passcode.active = 0;
          await this.putPasscode(this.passcode);
        }
      }
    }
  }
</script>

<style scoped>

</style>
